import * as React from 'react';
import { PageProps } from 'gatsby';
import { MainTemplate } from '../templates/main/main';
import { SEO } from '../components/seo/seo';
import { useIntl } from 'gatsby-plugin-react-intl';
import { NotFound } from '../ui/pages/404/not-found/not-found';

const NotFoundPage = ({ location }: PageProps) => {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'not_found.seo_title' })}
        robots="noindex,nofollow,noodp"
      />
      <div className="container">
        <NotFound />
      </div>
    </MainTemplate>
  );
};

export default NotFoundPage;
