import * as React from 'react';
import * as styles from './not-found.module.scss';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';

export const NotFound = () => (
  <div className={styles.wrapper}>
    <h1>
      <FormattedMessage id="not_found.headline" />
    </h1>
    <p>
      <FormattedMessage id="not_found.explanation" />
    </p>
    <Link to="/">
      <FormattedMessage id="not_found.back_to_home" />
    </Link>
  </div>
);
